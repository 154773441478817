.sqr0y8v{width:100%;height:602px;object-fit:cover;}@media (max-width:768px){.sqr0y8v{height:401px;}}
.s14bie8u{width:198px;height:111px;object-fit:contain;}@media (max-width:768px){.s14bie8u{height:62px;width:112px;margin-right:20px;}}
.s1ikr74f{max-width:1080px;height:100%;margin:0 auto;position:relative;margin-top:-165px;}@media (max-width:1100px) and (min-width:769px){.s1ikr74f{max-width:unset;}}@media (max-width:768px){.s1ikr74f{margin-top:-98px;}}
.b1boz32o{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;}.b1boz32o button{padding:7px 25px;}.b1boz32o button:hover{opacity:1;}.b1boz32o .ant-btn{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}@media (max-width:768px){.b1boz32o .ant-btn{height:34px !important;}}.b1boz32o span{padding-left:17px;}@media (max-width:768px){.b1boz32o span{padding-left:6px;}}@media (max-width:1200px){.b1boz32o{padding-right:20px;}.b1boz32o svg{height:18px;width:18px;}.b1boz32o span{font-size:12px;line-height:180%;}}
.m1q27mbk{box-shadow:0px 0px 50px rgba(27,27,27,0.02);background:#FFFFFF;padding:40px;margin-top:20px;border-radius:10px 10px 0px 0px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}.m1q27mbk h2{font-style:normal;font-weight:bold;font-size:30px;line-height:160%;margin-bottom:11px;}@media (max-width:768px){.m1q27mbk h2{font-size:24px;margin-bottom:20px;}}@media (max-width:768px){.m1q27mbk{border-radius:0px;padding-top:28px;padding-left:20px;padding-right:20px;padding-bottom:0px;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}}@media (max-width:1100px) and (min-width:769px){.m1q27mbk{margin-left:20px;margin-right:20px;}}
.s1dkk3dx{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;}.s1dkk3dx h3{margin-bottom:0px;font-style:normal;font-weight:bold;font-size:16px;line-height:25px;}.s1dkk3dx .ant-divider-vertical{height:1.3em;border-left:1px solid #1B1B1B;}.s1dkk3dx .ant-rate{color:#f0d23c;margin-left:8px;}.s1dkk3dx .ant-rate-star{margin-left:6px;margin-right:0px;}.s1dkk3dx .ant-tag{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-style:normal;font-weight:bold;font-size:12px;line-height:180%;color:#1B1B1B;height:18px;margin-left:9px;}@media (max-width:768px){.s1dkk3dx{margin-bottom:20px;}}
.pqhnufv{font-style:normal;font-weight:bold;font-size:16px;line-height:25px;color:#b6b6b6;margin:8px 0px 0px 0px;}@media (max-width:768px){.pqhnufv{display:none;}}
.tzkznmf{width:100%;margin:0 auto;padding-top:30px;background-color:#FFFFFF;}.tzkznmf .sticky{z-index:1000;left:0px;width:100% !important;top:50px !important;}.tzkznmf .menu{background-color:#FFFFFF;padding:0px;overflow:overlay;-webkit-scroll-behavior:smooth;-moz-scroll-behavior:smooth;-ms-scroll-behavior:smooth;scroll-behavior:smooth;}.tzkznmf .menu::-webkit-scrollbar{background:transparent;}.tzkznmf ul{border-bottom:1px solid #c4c4c4;padding-top:20px;max-width:1000px;margin:auto;}@media (max-width:768px){.tzkznmf ul{padding:20px 0px 0px;margin-right:20px;margin-left:20px;}.tzkznmf ul::-webkit-scrollbar{background:transparent;}}.tzkznmf .ant-menu-horizontal{line-height:unset;}.tzkznmf li{padding-bottom:20px !important;line-height:unset;padding-left:0px !important;padding-right:30px !important;}.tzkznmf li:after{left:0px !important;}.tzkznmf li:hover{color:#1B1B1B !important;}.tzkznmf li:hover span{font-weight:bold;}.tzkznmf li span{margin-bottom:17px !important;font-style:normal;font-weight:500;font-size:14px;line-height:160%;}@media (max-width:768px){.tzkznmf li span{line-height:130%;font-weight:normal;}}.tzkznmf .ant-menu-item-active{color:#1B1B1B !important;}.tzkznmf .ant-menu-item-active::after{border-bottom:0px !important;}.tzkznmf .ant-menu-item-selected{color:#1B1B1B !important;}.tzkznmf .ant-menu-item-selected span{font-weight:bold;}.tzkznmf .ant-menu-item-selected:after,.tzkznmf li:hover:after{border-bottom:4px solid #FA5F00 !important;}.tzkznmf .ant-menu-submenu-title{padding:0;display:none;}@media (max-width:1100px) and (min-width:769px){.tzkznmf .menu{margin-left:40px;margin-right:40px;}}
.r1jp62nu{margin-left:30px;-webkit-flex:1;-ms-flex:1;flex:1;}.r1jp62nu p{color:#1B1B1B;}@media (max-width:768px){.r1jp62nu{-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;margin-left:0px;margin-top:20px;}}@media (min-width:769px){.r1jp62nu{max-width:270px;}}
.l1fvbazq{white-space:break-spaces;max-width:700px;}.l1fvbazq > p{color:#1B1B1B;}
.wk0p4jl{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;max-width:1000px;margin:auto;width:100%;margin-top:30px;}@media (max-width:1100px) and (min-width:769px){.wk0p4jl{max-width:unset;margin-left:60px;margin-right:60px;width:auto;}}@media (max-width:768px){.wk0p4jl{padding:0px 20px;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}}@media (min-width:769px){.wk0p4jl{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}}
.m1rykbym{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-bottom:19px;}.m1rykbym a{-webkit-text-decoration-line:underline;text-decoration-line:underline;white-space:break-spaces;font-weight:500;font-size:14px;line-height:22px;color:#FA5F00;margin-bottom:0px;cursor:pointer;}.m1rykbym a:hover{color:#00A0D2;}@media (max-width:768px){.m1rykbym{margin-bottom:23px;}}
.m14v5lsf{font-weight:normal;font-size:14px;line-height:22px;font-weight:500;white-space:break-spaces;margin-bottom:30px;}@media (max-width:768px){.m14v5lsf{margin-bottom:22px;}}
.i1wakvo3{border:1px solid #E0E0E0;padding:30px;border-radius:10px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.i1wakvo3 p{font-weight:500;font-size:16px;line-height:30px;font-weight:normal;margin-bottom:20px;}@media (max-width:768px){.i1wakvo3 p{margin-bottom:22px;}}.i1wakvo3 button{font-weight:700;font-size:14px !important;}@media (min-width:769px){.i1wakvo3 button{min-width:210px;}}@media (max-width:768px){.i1wakvo3{padding:30px 20px 31px 20px;}.i1wakvo3 span{font-size:16px;line-height:25px;}.i1wakvo3 button{min-width:295px;margin:auto;padding:0 50px;}}@media (max-width:374px){.i1wakvo3 button{min-width:0;padding:0 40px;}}
.c1hfli7 .ant-divider{border-color:#c4c4c4;}.c1hfli7 .section-divider{margin:50px 0;}@media (max-width:768px){.c1hfli7 .section-divider{margin:30px 0px;}}@media (max-width:768px){.c1hfli7{padding:0px;}}
.b1j6jaw7{max-width:1000px;margin:auto;width:100%;}@media (max-width:1100px){.b1j6jaw7{max-width:unset;width:auto;}}@media (max-width:1100px) and (min-width:769px){.b1j6jaw7{margin-left:60px;margin-right:60px;}}@media (max-width:768px){.b1j6jaw7{margin-left:20px;margin-right:20px;}}
.sxb07n2{max-width:1000px;margin:auto;}@media (max-width:1100px) and (min-width:769px){.sxb07n2{max-width:unset;margin-left:60px;margin-right:60px;}}@media (max-width:768px){.sxb07n2{margin-left:20px;margin-right:20px;}}
.c1q78rqk{margin-top:50px;}
.t192njfu{font-size:24px;font-weight:bold;line-height:34px;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;color:#1B1B1B;margin-bottom:30px;}@media (max-width:768px){.t192njfu{font-size:24px;line-height:38px;}}
.t1836v0s{font-size:24px;font-weight:bold;line-height:34px;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;position:relative;color:#1B1B1B;margin-bottom:30px;padding-left:30px;}@media (max-width:768px){.t1836v0s{font-size:20px;line-height:28px;}}.t1836v0s::after{content:"";position:absolute;top:50%;left:0;width:17px;height:4px;background:#F0D23C;}@media (max-width:768px){.t1836v0s{margin-bottom:20px;font-size:20px;line-height:28px;}}
.sxu2nbv{font-style:normal;font-weight:bold;font-size:16px;line-height:25px;color:#b6b6b6;margin-bottom:0px;}@media (min-width:769px){.sxu2nbv{display:none;}}
.f9c07kn{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;max-width:1000px;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:30px;}.f9c07kn h3{margin-bottom:0px;}@media (max-width:768px){.f9c07kn{margin-bottom:20px;}}
.f1xhvud7{background:#F5F5F5;width:100%;margin-top:40px;}
.l1svhldu{padding-top:50px;padding-bottom:50px;max-width:1000px;margin:auto;}@media (min-width:1440px){.l1svhldu{padding-left:220px;max-width:1440px;}}@media (max-width:1100px) and (min-width:769px){.l1svhldu{max-width:unset;padding-left:60px;}}@media (max-width:768px){.l1svhldu{padding-left:20px;}}@media (max-width:750px){.l1svhldu{padding-right:20px;}}
.c1qluyu5{padding-bottom:0;}
.s1dukk0y{height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;margin-bottom:-100px;padding-bottom:100px;}@media (max-width:449px){.s1dukk0y{padding-bottom:50px;}}
.bw8rbcx{display:none;}@media (min-width:768px){.bw8rbcx{display:inline-block;}}
.iz20hkg{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.c1nexri7 .ant-skeleton{width:100%;}.c1nexri7 .ant-skeleton-image{height:400px;width:100%;}@media (max-width:768px){.c1nexri7 .ant-skeleton-image{height:300px;}}
